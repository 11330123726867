/* import React, { useState, useEffect } from 'react';
import './carousel.css'; // Make sure to adjust the path

const Carouseltwo = ({ children }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const goToNextSlidetwo = () => {
    setActiveSlideIndex((prevIndex) => (prevIndex + 1) % children.length);
  };

  const goToPrevSlidetwo = () => {
    setActiveSlideIndex((prevIndex) =>
      prevIndex === 0 ? children.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(goToNextSlidetwo, 10000); // Change slide every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div className="carousel-content-wrapper">
          <div
            className="carousel-content"
            style={{ transform: `translateX(-${activeSlideIndex * 100}%)` }}
          >
            {children}
          </div>
        </div>
      </div>
      <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <tr>
          <td></td>
          <td>
            <button onClick={goToPrevSlidetwo}>&#129092;</button>
            <button onClick={goToNextSlidetwo}>&#129094;</button>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  );
};

export default Carouseltwo; */

import React, { useState, useEffect } from 'react';
import './carousel.css'; // Make sure to adjust the path

const Carouseltwo = ({ children }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const goToNextSlidetwo = () => {
    setActiveSlideIndex((prevIndex) => (prevIndex + 1) % children.length);
  };

  const goToPrevSlidetwo = () => {
    setActiveSlideIndex((prevIndex) =>
      prevIndex === 0 ? children.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(goToNextSlidetwo, 10000); // Change slide every 10 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div className="carousel-content-wrapper">
          {React.Children.map(children, (child, index) => (
            <div
              className={`carousel-content ${index === activeSlideIndex ? 'active' : ''}`}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <tr>
          <td></td>
          <td>
            <button onClick={goToPrevSlidetwo}>&#129092;</button>
            <button onClick={goToNextSlidetwo}>&#129094;</button>
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  );
};

export default Carouseltwo;