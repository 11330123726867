import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SEO from '../components/seo';
import Layout from '../components/layout';

import { StaticImage } from 'gatsby-plugin-image';
import Carouseltwo from '../components/hardingproject/carouseltwo'; // Adjust the path



const boxStyle = {
  //height: '50px',
  //width: '50px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  //fontSize: '30px',
  cursor: 'pointer',
  color: 'black',
  padding: '5px',
  textDecoration: 'underline', // Add underline effect
};

const videoStyles = {
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '50%',
   height:'300px'
}

export default function NewApp() {
  return (
    <Layout>
      <SEO title="microlearning" />
      <React.Fragment>
            <div
            
            >
             <h3><img style={{display: 'block',  display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', height: 'auto', boxShadow: '0 4px 8px black'}} src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/ThePulse.png" alt="usamteac logo" width="669" height="115" /></h3>  
            </div>                      
          </React.Fragment>
      <br />
      <div>
</div>
      <div>
        <Carouseltwo>
          {/* Add your content (slides) here */}
          <div style={{ position: 'relative' }}>
            <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', height: '300px', objectFit: 'cover', position: 'relative'}} src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/MedicalOperation.jpg" />
            <Link to="https://medcoe.army.mil/">
              <button css={{position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(138,39,64,1)', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer'}}>Learn More <FaExternalLinkAlt /></button>
            </Link>
          </div>
          <div style={{ position: 'relative' }}>
            <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', height: '300px', objectFit: 'cover', position: 'relative'}} src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/MedivacDog.jpg" />
            <Link to="https://medcoe.army.mil/">
              <button css={{position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(138,39,64,1)', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer'}}>Learn More <FaExternalLinkAlt /></button>
            </Link>
          </div>
          <div style={{ position: 'relative' }}>
            <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', height: '300px', objectFit: 'cover', position: 'relative'}} src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/MedivacHumvee.jpg" />
            <Link to="https://medcoe.army.mil/">
              <button css={{position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(138,39,64,1)', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer'}}>Learn More <FaExternalLinkAlt /></button>
            </Link>
          </div>
          <div style={{ position: 'relative' }}>
            <img style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '50%', height: '300px', objectFit: 'cover', position: 'relative'}} src="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/MedivacTank.jpg" />
            <Link to="/your-link">
              <button css={{position: 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(138,39,64,1)', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer'}}>Learn More <FaExternalLinkAlt /></button>
            </Link>
          </div>
          {/* ... more slides ... */}
        </Carouseltwo>
      </div>
      {/* ... widget rss feed for https://www.hardingproject.com/  used widget from https://rss.app/rss-feed... */}
       {/* <h3 style={{ color:'black', textAlign: 'center', textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)'}}>RSS Feed Harding Project</h3> */}
      <center><h2 style={{ fontStyle: 'italic', color: '#555', borderLeft: '4px solid #ccc', paddingLeft: '10px', margin: '20px 0' }}>
      Good writing is a story that grows legs and has a life of it's own.
      <footer style={{ fontSize: '0.9em', color: '#333', textAlign: 'center' }}>
      — Brigadier General Clinton K. Murray
      <br />
      </footer>     
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <img src="https://medcoe.army.mil/static/bfc64ef5a61ab56c71d79027b614df11/39633/BGMurray.webp" alt="BG Murray" style={{ width: '75px', height: 'auto' }} />
      </div>
    </h2>
    </center>
      <center>
  <table style={{ width: '100%', borderCollapse: 'collapse' }}>
    <tbody>
      <tr>
        <td style={{ textAlign: 'center', padding: '0',border: 'none' }}>
          <a
            style={{
              display: 'inline-block',
              padding: '10px 10px',
              fontSize: '16px',
              color: 'white',
              backgroundColor: '#005a9c',
              border: 'none',
              borderRadius: '4px',
              textAlign: 'center',
              textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
              textDecoration: 'none',
              margin: '0' // Added to remove any default margin
            }}
            href="https://www.hardingproject.com/p/welcome-to-the-harding-project"
            target="_blank"
            rel="noopener noreferrer"
          >
            Harding Project
          </a>
          &nbsp;&nbsp;
          <a
            style={{
              display: 'inline-block',
              padding: '10px 10px',
              fontSize: '16px',
              color: 'white',
              backgroundColor: '#005a9c',
              border: 'none',
              borderRadius: '4px',
              textAlign: 'center',
              textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
              textDecoration: 'none',
              margin: '0' // Added to remove any default margin
            }}
            href="https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/pfw-images/site/PulseSubGuidelines.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Pulse Submission Guidelines
          </a>
          &nbsp;&nbsp;
          <a
            style={{
              display: 'inline-block',
              padding: '10px 10px',
              fontSize: '16px',
              color: 'white',
              backgroundColor: '#005a9c',
              border: 'none',
              borderRadius: '4px',
              textAlign: 'center',
              textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
              textDecoration: 'none',
              margin: '0' // Added to remove any default margin
            }}
            href="https://medcoe.army.mil/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Topics Of Interest
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</center>
<br />
{/* ... widget rss feed for https://www.hardingproject.com/  used widget from https://rss.app/rss-feed... */}
         {/* <center><iframe width="900" height="1600" src="https://rss.app/embed/v1/wall/3W5wD4ESN2sU4m3f" frameborder="0"></iframe></center> */}
      
    </Layout>
  );
}